const entrySound = require('../assets/sound/doorbell-5.mp3');
const errorSound = require('../assets/sound/error.mp3');
const messageSound = require('../assets/sound/message.mp3');
const popSound = require('../assets/sound/happy-pop-3.mp3');
const newMessageSound = require('../assets/sound/new-message.mp3');
const littleBellSound = require('../assets/sound/little-bell.mp3');


const useSoundPlayer = () => {
    const playSound = (soundFile) => {
        const newAudio = new Audio(soundFile);
        newAudio.play().catch(error => console.error('Error playing sound:', error));
    };

    const playMessageSound = () => playSound(messageSound);
    const playErrorSound = () => playSound(errorSound);
    const playEntrySound = () => playSound(entrySound);
    const playPopSound = () => playSound(popSound);
    const playNewMessageSound = () => playSound(newMessageSound);
    const playLittleBellSound = () => playSound(littleBellSound);

    return {
        playMessageSound,
        playErrorSound,
        playEntrySound,
        playPopSound,
        playNewMessageSound,
        playLittleBellSound,
    };
};

export default useSoundPlayer;
