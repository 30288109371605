import React, {useEffect, useState} from 'react';
import {getEchoInstance} from "../../../services/pusherEchoInstance";
import {Badge, Box, List, ListItem, Popover} from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import PeopleIcon from '@mui/icons-material/People';
import ConfirmAction from "../../../components/confirmAction/ConfirmAction";
import useAxiosRequest, {POST} from "../../../hooks/useAxiosRequest";
import LogoutIcon from '@mui/icons-material/Logout';
import {enqueueSnackbar} from "notistack";
import {useSelector} from "react-redux";
import {isSuperAdmin} from "../../../store/slices/authSlice";

const Presence = ({branchCode}) => {
    const [users, setUsers] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const axiosRequest = useAxiosRequest();
    const isSuperAdmintatus = useSelector(isSuperAdmin);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    function handleRemoteLogout(user) {
        const [request] =
            axiosRequest(POST, `/api/auth/remote-logout`, {
                'user_id': user.user_id
            });

        request.then(response => {
            enqueueSnackbar(`${user.user_info?.name} remotely logged out`, {variant: 'success'});
        }).catch(error => {
        });
    }

    useEffect(() => {
        if (!branchCode) return;

        const echo = getEchoInstance();
        const presenceChannel = `presence.${branchCode}`;

        const channelRef = echo.join(presenceChannel)
            .here((currentUsers) => {
                setUsers(currentUsers);
            })
            .joining((joiningUser) => {
                setUsers(prevUsers => [...prevUsers, joiningUser]);
            })
            .leaving((leavingUser) => {
                setUsers(prevUsers => prevUsers.filter(u => u.id !== leavingUser.id));
            });

        return () => {
            echo.leave(presenceChannel);
        };
    }, [branchCode]);

    return (
        <div>
            <Badge
                badgeContent={users.length}
                color="secondary"
                onMouseEnter={handlePopoverOpen}
            >
                <PeopleIcon/>
            </Badge>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                onMouseLeave={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{padding: 2}}>
                    <List>
                        {users.map(user => (
                            <ListItem key={`user_${user.user_id}`} disableGutters>
                                <ListItemText primary={user.user_info?.name}/>
                                {isSuperAdmintatus &&
                                    <ConfirmAction onOk={(e) => handleRemoteLogout(user)}
                                                   toolTip={"Remotely log this user out?"}
                                                   icon={<LogoutIcon fontSize="small"/>}/>
                                }
                            </ListItem>
                        ))}
                    </List>
                </Box>
            </Popover>
        </div>
    );
};

export default Presence;
