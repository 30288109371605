import React, {useState, useEffect} from 'react';
import {Grid, Paper, Typography, Box, Button, Backdrop} from "@mui/material";
import Base from "../base/Base";
import {setShowHeader} from "../../store/slices/navSlice";
import {setAuth} from "../../store/slices/authSlice";
import css from "./Login.module.css";
import * as Yup from "yup";
import FormComponent, {FieldTypes} from "../../components/formik/FormComponent";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useNavigate} from 'react-router-dom';
import Otp from "./Otp";
import axios from "../../store/tokenAxios/tokenAxios";
import Helpers from "../../components/helpers/Helpers";
import {INFO, setError} from "../../store/slices/errorSlice";

const Login = () => {
    const [loginMode] = useState('email');  // State to hold the login mode
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const auth = useSelector(state => state.auth);
    const [lastValues, setLastValues] = useState({});
    const [reset, setReset] = useState(false);
    const [resetMessage, setResetMessage] = useState("");
    const [userInfo, setUserInfo] = useState({
        userIdHash: null,
        transport: null,
        otpRequired: false,
    });
    const [showConfirmation, setShowConfirmation] = useState(null);
    const location = useLocation();

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const emailConfirmed = queryParams.get('emailConfirmed');

        if (emailConfirmed === 'true') {
            setShowConfirmation(true);
        }
        if (emailConfirmed === 'false') {
            setShowConfirmation(false);
        }
    }, [location]);

    useEffect(() => {
        dispatch(setShowHeader(false))

        const token = localStorage.getItem('dam_admin_token');
        if (token) {
            const parsedToken = JSON.parse(token);
            if (parsedToken && parsedToken.user && Array.isArray(parsedToken.user.roles)) {
                dispatch(setAuth(parsedToken));
            }
        }

        return () => {
            dispatch(setShowHeader(true))
        }
    }, [navigate, dispatch]);

    useEffect(() => {
        if (auth && auth.token) {
            navigate('/operations');
        }
    }, [auth, navigate]);

    const sendReset = () => {
        setReset(null)

        axios.post(`/api/auth/password/forgot`,{
            email: lastValues.email,
        }).then((response) => {
            console.log(response);
            if( response.data.status === 'passwords.sent') {
                setResetMessage("Check your email and follow the instructions to reset your password");
            } else {
                setResetMessage("Enter a registered email address and try again");
            }
            setReset(true);
        }).catch((error) => {
            const show = Helpers.parseError(error);
            dispatch(setError({
                error: show,
                severity: INFO
            }));
            setReset(false);
        });
    }

    const onConfirmed = (response, values) => {
        setUserInfo(prevState => ({
            ...prevState,
            otpRequired: true,
            userIdHash: response.data.original.user_id_hash,
            transport: response.data.original.transport,
        }));
    };

    const formFields = [
        {
            label: 'Email',
            name: 'email',
            variant: 'outlined',
            fullWidth: true,
            gridFullWidth: true,
            type: FieldTypes.TEXT,
        },
        {
            label: 'Password',
            name: 'password',
            variant: 'outlined',
            fullWidth: true,
            gridFullWidth: true,
            maskType: 'password',
            type: FieldTypes.TEXT,
        }
    ];

    const validationSchema =
        Yup.object().shape({
            email: Yup.string()
                .required('Email is required')
                .email('Email is not valid'),
            password: Yup.string().required('Password is required')
        });

    const initialValues = {
        email: '',
        password: '',
    };

    //console.log(initialValues, validationSchema, formFields)

    return (
        <Base>
            <>
            { showConfirmation !== null &&
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1}}
                    open={true}
                >
                    <Paper component={Box} p={4}>
                        {showConfirmation === true &&
                            <Typography variant="h6" gutterBottom>
                                Your email address has been confirmed, you can generate login tokens now
                            </Typography>
                        }
                        {showConfirmation === false &&
                            <Typography variant="h6" gutterBottom>
                                Your validation token has expired, please request a new one
                            </Typography>
                        }
                        <Button variant={"contained"} onClick={() => setShowConfirmation(null)}>Close</Button>
                    </Paper>
                </Backdrop>
            }
            { reset === true &&
                <Backdrop
                    sx={{color: '#fff', zIndex: (theme) => theme.zIndex.modal + 1}}
                    open={true}
                >
                    <Paper component={Box} p={4}>
                            <Typography variant="h6" gutterBottom>
                                {resetMessage}
                            </Typography>
                        <Button variant={"contained"} onClick={() => setReset(false)}>Close</Button>
                    </Paper>
                </Backdrop>
            }
            <Grid container justifyContent="center" alignItems="center">
                <Grid item xs={12} md={6} lg={4}>
                    { userInfo.otpRequired  ?
                        <Otp email={lastValues.email}
                            password={lastValues.password}
                            userInfo={userInfo}
                            onCancel={() => setUserInfo(prevState => ({
                                ...prevState,
                                otpRequired: false
                            }))}
                        ></Otp>
                        :
                        <Paper component={Box} p={4} textAlign="center" className={css.paper}>
                            <Typography variant="h6" gutterBottom>
                                Vaal Triangle Couriers Admin
                            </Typography>
                            <FormComponent
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={onConfirmed}
                                formFields={formFields}
                                submitLabel="Get OTP"
                                url={`/api/auth/login`}
                                verb="post"
                                additionalData={{country: "27"}}
                                onValuesChanged={(values,form) => {
                                    setTimeout(() => {
                                        setLastValues(values);
                                    }, 0);
                                }}
                            />
                            <Box mt={3}>
                                <Button
                                    onClick={sendReset}
                                    variant="outlined"
                                    color="secondary"
                                    type="button"
                                    fullWidth
                                    disabled={reset !== false}
                                >
                                    Forgot your password?
                                </Button>
                            </Box>
                        </Paper>
                    }
                </Grid>
            </Grid>
            </>
        </Base>
    );
};

export default Login;
