import React from 'react';
import { TextField } from '@mui/material';
import { useFormikContext } from 'formik';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'; // or '@date-io/date-fns' depending on version
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {DatePicker} from "@mui/x-date-pickers";

const DatePickerField = ({ label, name, readonly }) => {
    const { values, setFieldValue } = useFormikContext();
    const selectedDate = values[name];

    const handleDateChange = (date) => {
        // Format the date as needed or use it directly if your backend accepts Date objects
        setFieldValue(name, date);
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
                label={label}
                value={selectedDate}
                onChange={handleDateChange}
                readOnly={readonly}
                slotProps={{ textField: { variant: 'outlined' } }}
                disabled={readonly || false}
            />
        </LocalizationProvider>
    );
};

export default DatePickerField;
