import Echo from 'laravel-echo';
import {signal} from "@preact/signals-react";

export const pusherConnectedSignal = signal(false);

window.Pusher = require('pusher-js');

if (undefined === window.echoInstance)
    window.echoInstance = null;

export function authHeader() {
    const token = localStorage.getItem('dam_admin_token');
    if (token) {
        return `Bearer ${JSON.parse(token).token}`;
    }
    return null;
}

/*
var pusher = new Pusher("app_key", {
  userAuthentication: {
    endpoint: "/pusher/user-auth",
    transport: "ajax",
    params: {},
    headers: {},
    customHandler: null,
  },
});
 */

const createEchoInstance = (onConnectedCallback) => {
    //const urlObject = new URL(process.env.REACT_APP_API_BASE_URL);
    //const baseUrl = `${urlObject.hostname}`;

    if (!window.echoInstance) {
        const options = {
            authEndpoint: process.env.REACT_APP_API_BASE_URL + '/api/auth/authenticate',
            broadcaster: 'pusher',
            key: process.env.REACT_APP_API_MIX_PUSHER_APP_KEY,
            wsHost: process.env.REACT_APP_API_PUSHER_URL, //baseUrl,
            wsPort: parseInt( process.env.REACT_APP_API_PUSHER_PORT),
            wssPort: parseInt( process.env.REACT_APP_API_PUSHER_PORT_SSL),
            encrypted: true,
            disableStats: true, // this disables stats for stats.pusher.com, we dont use this
            forceTLS: process.env.REACT_APP_API_PUSHER_FORCE_TLS==='true',
            cluster: "bg1",
            enabledTransports: ['ws', 'wss'],
            auth: {
                headers: {
                    Authorization: authHeader(),
                }
            }
        };

        //console.log('echo options', process.env.REACT_APP_API_PUSHER_FORCE_TLS, options);

        try {
            window.echoInstance = new Echo(options);

            window.echoInstance.connector.pusher.connection.bind('state_change', states => {
                console.log('State changed from', states.previous, 'to', states.current);
                pusherConnectedSignal.value = states.current;

                if( onConnectedCallback)
                   onConnectedCallback(states.current);
            });
        } catch( error) {
            console.error('echo error', error);
        }
    }
    return window.echoInstance;
};

export const getEchoInstance = (onConnectedCallback=null) => {
    return createEchoInstance(onConnectedCallback);
};

