import React from 'react';
import {green, orange, red} from "@mui/material/colors";
import {FiberManualRecord} from "@mui/icons-material";


const ConnectionIndicator = ({ isConnected }) => {
    const color = isConnected.value === 'connected' ? green[500] : (isConnected.value === 'connecting' ? orange[500] : red[500]);

    return (
        <FiberManualRecord style={{ color: color }} />
    );
};

export default ConnectionIndicator;
