import { useEffect, useState } from 'react';

function ExtensionChecker() {
  const [isExtensionInstalled, setIsExtensionInstalled] = useState(false);


    useEffect(() => {
        if( !window.chrome.runtime)
            return;

        const extensionId = process.env.REACT_APP_EXTENSION_ID;
        const port = window.chrome.runtime.connect(extensionId);

        port.postMessage('version_check');
        console.log('Checking for extension');
        port.onMessage.addListener((msg) => {
            console.log(msg);

            if (msg.version) {
                setIsExtensionInstalled(true);
            }
        });

        port.onDisconnect.addListener(() => {
            setIsExtensionInstalled(false);
        });

        return () => port.disconnect();
    }, []);

    return (
        <div className="App">
            <h1>Check Chrome Extension</h1>
            {isExtensionInstalled ? (
                <p>Extension is installed</p>
            ) : (
                <p>Extension is not installed</p>
            )}
        </div>
    );
}

export default ExtensionChecker;