import { createSlice } from '@reduxjs/toolkit';
import { setAuth } from './authSlice';

const dataSlice = createSlice({
    name: 'data',
    initialState: {
        roles: [],
        groups: [],
        officeReasons: [],
        unshiftReasons: [],
        selectedGroup: localStorage.getItem('dam_admin_group') || '',
    },
    reducers: {
        setData( state, action) {
            state.roles = action.payload.roles.map((role) => ({ value: role.id, label: role.name }));
            state.groups = action.payload.groups; //.map((group) => ({ value: group.id, label: group.name }))
            state.officeReasons = action.payload.office_reasons;
            state.unshiftReasons = action.payload.unshift_reasons;
        },
        setSelectedGroup(state, action) {
            state.selectedGroup = action.payload;
            localStorage.setItem('dam_admin_group', action.payload);
        },
        setUnshiftReasons(state, action) {
            state.unshiftReasons = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setAuth, (state, action) => {
            const parsedToken = action.payload;
            if(parsedToken && parsedToken.user) {
                state.selectedGroup = parsedToken.user.group_id;
            }
        });
    }
});

export const { setData , setSelectedGroup, setUnshiftReasons} = dataSlice.actions
export default dataSlice.reducer;
