import React, {useEffect, useRef, useState} from 'react';
import {Form, Formik} from 'formik';
import {Box, Button, Grid, Typography, useTheme} from '@mui/material';
import Helpers from "../helpers/Helpers";
import axios from '../../store/tokenAxios/tokenAxios';
import SelectField from './SelectField';
import CheckboxGroup from './CheckboxGroup';
import ImagePreviewField from './ImagePreviewField';
import CheckboxField from "./CheckboxField";
import TextFieldField from "./TextFieldField";
import RadioGroupField from "./RadioGroupField";
import FormDivider from "./FormDivider";
import DatePickerField from "./DatePickerField";
import LabelDivider from "./LabelDivider";
import SearchField from "./SearchField";
import StringListField from "./StringListField";
import FileUploadField from "./FIleUploadField";
import {enqueueSnackbar} from "notistack";

export const handleImageUpload = (event, setFieldValue, fieldName) => {
    // Get the selected file from the input element
    const file = event.target.files[0];

    // Create a FormData object
    const formData = new FormData();

    // Append the file to the FormData object
    formData.append('image', file);

    const config = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    };

    axios.post('/api/admin/base64-image', formData, config).then((response) => {
        setFieldValue(fieldName, response.data.image);
    }).catch((error) => {
        const show = Helpers.parseError(error);
        /*dispatch(setError({
            error: show,
            severity: INFO
        }))*/
    })
};

const useContainerWidthMediaQuery = (containerRef, minWidth) => {
    const theme = useTheme();
    const [matches, setMatches] = useState(false);

    useEffect(() => {
        const updateMatches = () => {
            if (containerRef.current) {
                //console.log(containerRef.current.clientWidth, containerRef.current.clientWidth >= minWidth)
                setMatches(containerRef.current.clientWidth >= minWidth);
            }
        };

        updateMatches();
        window.addEventListener('resize', updateMatches);

        return () => {
            window.removeEventListener('resize', updateMatches);
        };
    }, [containerRef, minWidth]);

    return matches;
};

export const FieldTypes = Object.freeze({
    TEXT: 'text',
    CHECKBOX: 'checkbox',
    SELECT: 'select',
    CHECKBOX_GROUP: 'checkboxGroup',
    RADIO_GROUP: 'radioGroup',
    IMAGE_PREVIEW: 'imagePreview',
    DATE_PICKER: 'datePicker',
    DIVIDER: 'divider',
    LABEL_DIVIDER: 'labelDivider',
    SEARCH_FIELD: 'searchField',
    STRING_LIST: 'stringList',
    FILE_UPLOAD: 'fileUpload',
});

const FormComponent = ({
                           submitDisabled,
                           initialValues,
                           validationSchema,
                           onSubmit,
                           formFields,
                           submitLabel,
                           url,
                           verb,
                           additionalData,
                           onCancel,
                           onValuesChanged
                       }) => {
    const [error, setError] = useState(null);
    const theme = useTheme();
    const containerRef = useRef(null);
    const matchesMin600 = useContainerWidthMediaQuery(containerRef, 600);
    const matchesMin800 = useContainerWidthMediaQuery(containerRef, 800);

    const handleSubmit = (values, {setSubmitting}) => {
        if (url === undefined || verb === undefined) {
            if (onSubmit !== undefined)
                return onSubmit({...values, ...additionalData});
            else throw new Error('onSubmit is undefined and url and verb are undefined');
        }

        //console.log( values, additionalData, {...values, ...additionalData});
        const finalUrl = verb === "put" ? `${url}/${values.id}` : url;

        // filter out any empty valuesif the field type is set to  maskType: 'password',
        let newValues = {...values};
        let hasFiles = false;

        if (formFields) {
            formFields.forEach((field) => {
                if (field.maskType === 'password') {
                    if (!newValues[field.name] || newValues[field.name] === '' || newValues[field.name] === '******') {
                        delete newValues[field.name];
                    }
                }
                if (field.type === FieldTypes.FILE_UPLOAD) {
                    hasFiles = true;
                }
            });
        }

        let axiosPromise = null;

        const allValues = {...newValues, ...additionalData};

        if (hasFiles) {
            const formData = new FormData();
            Object.keys(allValues).forEach(key => {
                if (newValues[key] instanceof File) {
                    formData.append(key, values[key]);
                } else {
                    formData.append(key, newValues[key]);
                }
            });

            axiosPromise = axios[verb](finalUrl, formData);
        } else {
            axiosPromise = axios[verb](finalUrl, allValues);
        }

        axiosPromise
            .then((response) => {
                onSubmit(response, newValues);
            })
            .catch((error) => {
                const show = Helpers.parseError(error);
                enqueueSnackbar(show, {variant: 'error'});
                setError(show);
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const renderField = (field, errors, touched, form) => {
        // The expression {(() => { ... })()} is an immediately-invoked function expression (IIFE). It is a function that is defined and executed immediately after its creation.

        switch (field.type) {
            case FieldTypes.CHECKBOX:
                return <CheckboxField {...field} />;
            case FieldTypes.SELECT:
                return <SelectField {...field} />;
            case FieldTypes.CHECKBOX_GROUP:
                return <CheckboxGroup {...field} />;
            case FieldTypes.RADIO_GROUP:
                return <RadioGroupField {...field} />;
            case FieldTypes.IMAGE_PREVIEW:
                return <ImagePreviewField {...field}/>;
            case FieldTypes.DATE_PICKER:
                return <DatePickerField {...field} />;
            case FieldTypes.DIVIDER:
                return <FormDivider {...field}/>
            case FieldTypes.SEARCH_FIELD:
                return <SearchField {...field}/>
            case FieldTypes.STRING_LIST:
                return <StringListField {...field}/>
            case FieldTypes.LABEL_DIVIDER:
                return <LabelDivider {...field}/>
            case FieldTypes.FILE_UPLOAD:
                return <FileUploadField {...field} />;
            default:
                return <TextFieldField errors={errors} touched={touched} form={form} {...field} />;
        }
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {({values, isValid, isSubmitting, errors, touched, ...form}) => {
                if (onValuesChanged !== undefined)
                    onValuesChanged(values, form);

                return (
                    <Form>
                        <Grid mt={1} container spacing={2} ref={containerRef}>
                            {formFields.map((field) => {
                                const { gridFullWidth, ...restOfField } = field;
                                if (restOfField.name === undefined || restOfField.name === null)
                                    throw new Error('Field name is undefined');

                                return (
                                    <Grid item key={`frm_${restOfField.name}`}
                                          xs={gridFullWidth ? 12 : (matchesMin800 ? 4 : (matchesMin600 ? 6 : 12))}>
                                        {renderField(restOfField, errors, touched, form)}
                                    </Grid>
                                )
                            })}
                        </Grid>
                        {typeof error === 'string' && (
                            <Box m={2}>
                                <Typography color="primary" style={{color: "red"}}>{error}</Typography>
                            </Box>
                        )}
                        <Box mt={2} display="flex" justifyContent={onCancel !== undefined ? "center" : "center"}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                disabled={!isValid || isSubmitting || submitDisabled}
                            >
                                {submitLabel}
                            </Button>
                            {onCancel !== undefined &&
                                <Button style={{
                                    marginLeft: theme.spacing(2)
                                }} variant={"outlined"} color={"secondary"} onClick={onCancel}>Cancel</Button>
                            }
                            {/*errors && <pre>{JSON.stringify(errors, null, 2)}</pre>*/}
                            {/*touched && <pre>{JSON.stringify(touched, null, 2)}</pre>*/}
                        </Box>
                    </Form>
                )
            }}
        </Formik>
    );
};

export default FormComponent;

