import {Field} from "formik";

const HintField = ({ hint, ...props }) => (
    <>
        <Field {...props}>
        </Field>
        {hint && <p style={{fontSize:"0.7em"}}>{hint}</p>}
    </>
);

export default HintField;